import { routes as allRoutes } from '@/router/index';

export const getChildren = (name, routes) => {
  console.log(name, routes);
  for (const route of routes) {
    if (route.name === name) return route.children;
    if (route.children.length > 0) return getChildren(name, route.children);
    return false;
  }
  return false;
};

// export const getSibblings = (name, routes) => {

// };

export const findRouteByName = (name) => {
  function findId(id, arr) {
    return arr.reduce((a, item) => {
      if (a) return a;
      if (item.name === id) return item;
      if (item.children) return findId(name, item.children);
      return false;
    }, null);
  }
  return findId(name, allRoutes);
};

export const getParent = (name, routes, parent) => {
  for (const route of routes) {
    if (route.name === name) {
      return parent;
    }
    if (route.children && route.children.length > 0) {
      const res = getParent(name, route.children, route);
      if (typeof res === 'object') return res;
    }
  }
  return false;
};
