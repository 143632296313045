<template>
  <div id="StudentsTable">
    <sb-gql-table
      ref="table"
      :config="tableConfig"
      :query-options="queryOptions"
      :data-path="gqlDataPath"
      @cell-click-username="viewStudent"
      @row-action-update-password="editStudentPassword"
      @row-action-edit="editStudent"
      @bulk-action-archive="archiveStudents"
      @bulk-action-add-to-course-group="addStudentsToCourseGroup"
    >
      <template slot="column-username" slot-scope="{ content }">
        <span class="username">{{ content }}</span>
      </template>

      <template slot="column-organisations" slot-scope="{ content }">
        <template v-if="['admin'].includes($user.role)">
          <span
            v-for="organisation in content"
            :key="organisation.id"
            class="anchor"
            @click="handleOrganisationClick(organisation)"
          >
            {{ organisation.name }}
          </span>
        </template>
        <template v-for="organisation in content" v-else>
          {{ organisation.name }}
        </template>
      </template>

      <template slot="column-following-groups" slot-scope="{ content }">
        <template v-if="get(content, 'length')">
          <template v-if="$user.role === 'COACH'">
            <template v-for="(group, groupIndex) in content">
              <span v-if="!ownsGroup(group.id)" :key="group.id" class="group">
                {{ formatGroupName(group.name, groupIndex, content.length) }}
              </span>
              <span
                v-else
                class="group anchor"
                @click="handleCourseGroupClick(group.id)"
              >
                {{ formatGroupName(group.name, groupIndex, content.length) }}
              </span>
            </template>
          </template>

          <template v-else>
            <span
              v-for="(group, groupIndex) in content"
              :key="group.id"
              class="group"
              @click="handleCourseGroupClick(group.id)"
            >
              {{ formatGroupName(group.name, groupIndex, content.length) }}
            </span>
          </template>
        </template>

        <template v-else> Geen </template>
      </template>
    </sb-gql-table>

    <sb-add-students-to-course-group-modal
      v-model="modalAddToCourseGroup"
      :student-ids="studentsToAddToCourseGroup"
      @success="handleAddStudentsToCourseGroupSuccess"
    />
  </div>
</template>

<script>
import SbAddStudentsToCourseGroupModal from '@/components/SbAddStudentsToCourseGroupModal';
import AdminStudents from './AdminStudentsTable.gql';
import CoachStudents from './CoachStudentsTable.gql';
import OrganisationStudents from './OrganisationStudentsTable.gql';
import GroupStudents from './GroupStudentsTable.gql';
import { toCoachString } from '@/lib/data-transformers/to-coach-string';

import { nonReactiveMembersMixin } from '@/mixins/nonReactiveMembersMixin';
import SbGqlTable from '../SbTable2/SbGqlTable.vue';
import { TableConfig } from '../SbTable2/TableConfig';
import { DateTimeFilter, StringFilter } from '@/lib/gql-filters';
import gql from 'graphql-tag';

export default {
  components: {
    SbAddStudentsToCourseGroupModal,
    SbGqlTable,
  },

  mixins: [
    nonReactiveMembersMixin((self) => {
      const tableConfig = new TableConfig({
        rowActions: [
          ['update-password', 'Wachtwoord aanpassen'],
          ['edit', 'Gegevens bewerken'],
        ],
        columns: [
          {
            key: 'username',
            header: 'Gebruikersnaam',
            action: true,
            filter: 'search',
            sortable: true,
            fixed: 'left',
            width: 250,
            meta: { gqlFilter: StringFilter },
          },
          {
            key: 'fullName',
            header: 'Naam',
            filter: 'search',
            sortable: true,
            sort: 'ascending',
            meta: { gqlFilter: StringFilter },
            width: 250,
          },
          {
            key: 'followingGroups',
            header: 'Cursusgroep(en)',
            // width: 200,
            data: (row) => row.followingGroups || [],
          },
          {
            key: 'coaches',
            header: 'Begeleider(s)',
            data: (row) => toCoachString(row),
          },
          {
            key: 'createdAt',
            header: 'Aangemaakt',
            width: 160,
            sortable: true,
            data: (row) =>
              row.createdAt
                ? new Date(row.createdAt).toLocaleDateString()
                : '-',
          },
          {
            key: 'birthDate',
            header: 'Geboortedatum',
            sortable: true,
            width: 160,
            data: (row) =>
              row.birthDate
                ? new Date(row.birthDate).toLocaleDateString()
                : '-',
          },
          {
            key: 'treatmentStart',
            header: 'Begin begeleiding',
            sortable: true,
            filter: 'date',
            meta: { gqlFilter: DateTimeFilter },
            width: 320,
            data: (row) =>
              row.treatmentStart
                ? new Date(row.treatmentStart).toLocaleDateString()
                : '-',
          },
          {
            key: 'treatmentEnd',
            header: 'Einde begeleiding',
            sortable: true,
            filter: 'date',
            meta: { gqlFilter: DateTimeFilter },
            width: 320,
            data: (row) =>
              row.treatmentEnd
                ? new Date(row.treatmentEnd).toLocaleDateString()
                : '-',
          },
          {
            key: 'email',
            header: 'E-mailadres',
            width: 250,
            filter: 'search',
            meta: { gqlFilter: StringFilter },
          },
          ...(self.$user.context.isAdmin
            ? [
                {
                  key: 'organisations',
                  header: 'School',
                  width: 200,
                  data: (row) => row.organisations,
                },
              ]
            : []),
          {
            key: 'lastLogin',
            header: 'Laatst ingelogd',
            data: (row) => {
              if (!row.lastLogin) return 'Nooit';
              const date = new Date(row.lastLogin);
              return [
                date.toLocaleDateString(),
                date.toLocaleTimeString(),
              ].join(' ');
            },
          },
        ],
      });

      if (self.hideActions) return { tableConfig };

      if (self.$user.context.isAdmin) {
        tableConfig.bulkActions = ['archive', 'Archiveren'];
      }

      if (['MANAGER', 'ADMIN'].includes(self.$user.role)) {
        tableConfig.bulkActions = [
          ['add-to-course-group', 'Toevoegen aan cursusgroep'],
          ['archive', 'Archiveren'],
        ];
      }

      return { tableConfig };
    }),
  ],

  props: {
    organisationId: {
      type: String,
      default: null,
    },
    courseGroupId: {
      type: String,
      default: null,
    },
    isAdminContext: {
      type: Boolean,
      default: false,
    },
    hideActions: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    modalAddToCourseGroup: false,
    studentsToAddToCourseGroup: [],
  }),

  computed: {
    gqlDataPath() {
      // if viewing group as admin
      if (this.$user.context.isAdmin && this.courseGroupId) {
        return 'getGroupById.students';
      }

      // if viewing as admin
      if (this.$user.context.isAdmin) return 'users';

      // if in group view
      if (this.courseGroupId) return 'getGroupById.students';

      // if viewing as coach
      if (this.$user.role === 'COACH') return 'getUserById.coachingStudents';

      // if viewing as organisation
      if (this.organisationId) return 'getOrganisationById.students';

      throw new Error('Could not derive data path from context.');
    },
  },

  apollo: {
    me: {
      query: gql`
        query me {
          me {
            id
            coachingGroups {
              id
              name
            }
          }
        }
      `,
      skip() {
        return !this.$user.role === 'COACH';
      },
    },
  },

  methods: {
    toCoachString,

    formatGroupName(name, index, groupsAmount) {
      return name + (groupsAmount > 1 && index < groupsAmount - 1 ? ', ' : '');
    },

    ownsGroup(groupId) {
      return this.me.coachingGroups.find((group) => group.id === groupId);
    },

    handleAddStudentsToCourseGroupSuccess() {
      this.$refs.table.refresh();
      this.$refs.table.unselectAllRows();
    },

    queryOptions({
      first,
      last,
      after,
      before,
      filter,
      filters,
      orderBy,
      pagination,
    }) {
      let query;
      const variables = {
        first,
        last,
        after,
        before,
        orderBy,
        filter,
        id: undefined,
      };

      // if viewing group as admin
      if (this.$user.context.isAdmin && this.courseGroupId) {
        query = GroupStudents;
        variables.id = this.courseGroupId;
      }

      // if viewing as admin
      else if (this.$user.context.isAdmin) {
        query = AdminStudents;
        variables.filter.role = 'STUDENT';
      }

      // if in group view
      else if (this.courseGroupId) {
        query = GroupStudents;
        variables.id = this.courseGroupId;
      }

      // if viewing as coach
      else if (this.$user.role === 'COACH') {
        query = CoachStudents;
        variables.id = this.$user.id;
      }

      // if viewing as organisation
      else if (this.organisationId) {
        query = OrganisationStudents;
        variables.id = this.organisationId;
      }

      if (!query) throw new Error('Unable to determine query');

      // variables() {
      if (
        this.$user.context.resourceId === 'default' &&
        this?.paginationVariables?.filter
      ) {
        this.paginationVariables.filter.role = 'STUDENT';
      }

      return {
        query,
        variables,
      };
    },

    viewStudent(student) {
      const params = {
        resourceType: 'school',
        studentId: student.id,
        resourceId: (() => {
          if (this.$user.context.isAdmin) return student.organisations?.[0]?.id;
          if (this.$user.role === 'ADMIN' && !this.$user.context.isAdmin) {
            return this.$user.context.resourceId;
          }
          if (
            ['COACH', 'MANAGER'].includes(this.$user.role) &&
            this.courseGroupId
          ) {
            return this.$user.resourceId;
          }
          return this.organisationId;
        })(),
      };

      if (this.$user.role === 'COACH') {
        return this.$router.push({
          name: 'ResourceManagementStudentsStudentCurrentPaths',
          params,
        });
      }

      this.$router.push({
        name: 'ResourceManagementStudentsStudent',
        params,
      });
    },

    editStudent(student) {
      this.$router.push({
        name: 'ResourceManagementStudentsStudent',
        params: {
          resourceType: 'school',
          resourceId: student.organisations?.[0]?.id,
          studentId: student.id,
        },
        query: {
          edit: true,
        },
      });
    },

    editStudentPassword(student) {
      this.$router.push({
        name: 'ResourceManagementStudentsStudentEditPassword',
        params: {
          resourceType: 'school',
          resourceId: student.organisations?.[0]?.id,
          studentId: student.id,
        },
      });
    },

    archiveStudents(students) {
      const amount = students.length;
      const multiple = amount > 1;
      const content = [
        'Weet je zeker dat je',
        multiple ? amount : 'deze',
        multiple ? 'leerlingen' : 'leerling',
        'wil archiveren?',
      ].join(' ');

      this.$Modal.confirm({
        title: 'Let op!',
        content,
        closable: true,
        onOk: async () => {
          try {
            await this.$apollo.mutate({
              mutation: gql`
                mutation ArchiveUsers($input: UsersSelectionInput!) {
                  archiveUsers(input: $input)
                }
              `,
              variables: {
                input: { userIds: students.map(({ id }) => id) },
              },
            });

            this.$refs.table.refresh();
            this.$refs.table.unselectAllRows();
          } catch (error) {
            console.error(error);
            this.$Message.error('Er ging iets mis...');
          }
        },
      });
    },

    addStudentsToCourseGroup(students) {
      this.modalAddToCourseGroup = true;
      this.studentsToAddToCourseGroup = students.map(({ id }) => id);
    },

    assignLicenses(students) {},

    unassignLicenses(students) {},

    async handleCourseGroupClick(id) {
      console.log(this.$user);
      let resourceId;

      // for admin context navigation we also need to know the school id
      if (this.$user.resourceId === 'default') {
        const { data } = await this.$apollo.query({
          query: gql`
            query getGroupById($id: ID!) {
              getGroupById(id: $id) {
                id
                schoolId
              }
            }
          `,
          variables: {
            id,
          },
        });

        resourceId = data.getGroupById.schoolId;
      }
      this.$router.push({
        name: 'ResourceManagementCourseGroupsCourseGroup',
        params: {
          resourceType: 'school',
          ...(resourceId ? { resourceId } : {}),
          courseGroupId: id,
        },
      });
    },

    handleOrganisationClick(organisation) {
      this.$router.push({
        name: 'ResourceManagementSchoolsSchool',
        params: {
          resourceType: 'school',
          organisationId: organisation.id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.group {
  padding-right: 0.3em;
  white-space: nowrap;

  &.anchor {
    color: $brand-primary;
    text-decoration: underline;
    cursor: pointer;
    transition: color 0.3s;

    &:hover {
      color: $brand-black;
      transition-duration: 0s;
    }
  }
}

.username {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  display: inline-block;
}
</style>
