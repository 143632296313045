<template>
  <modal
    :value="value"
    title="Toevoegen aan cursusgroep"
    @input="$emit('input', $event)"
    @on-ok="addStudentsToGroup"
  >
    <i-select v-model="selectedCourseGroupId" filterable>
      <i-option v-for="item in courseGroups" :key="item.id" :value="item.id">
        {{ item.name }}
      </i-option>
    </i-select>
  </modal>
</template>

<script>
import gql from 'graphql-tag';

export default {
  name: 'SbAddStudentsToCourseGroupModal',

  props: {
    studentIds: { type: Array, default: () => [] },
    value: { type: Boolean, default: false },
  },

  data: () => ({ selectedCourseGroupId: undefined }),

  methods: {
    async addStudentsToGroup() {
      if (!this.studentIds?.length) return;

      try {
        const {
          data: {
            addUsersToGroup: { students, name },
          },
        } = await this.$apollo.mutate({
          mutation: gql`
            mutation AddStudentsToGroup($input: AddUsersToGroupInput!) {
              addUsersToGroup(input: $input) {
                id
                name
                students {
                  edges {
                    node {
                      id
                    }
                  }
                }
              }
            }
          `,
          variables: {
            input: {
              data: { studentIds: this.studentIds },
              id: this.selectedCourseGroupId,
            },
          },
        });

        const amount = students.edges.map(({ node }) => node).length;
        const multiple = amount > 1;
        const message = [
          multiple ? 'Leerlingen' : 'Leerling',
          `toegevoegd aan ${name}`,
        ].join(' ');

        this.$emit('success');
        this.$Message.success(message);
      } catch (error) {
        console.error(error);
        this.$emit('input', false);
        this.$Message.error('Er ging iets mis...');
      }
    },
  },

  apollo: {
    courseGroups: {
      skip() {
        return !this.value;
      },

      query() {
        return gql`
          query AddStudentsToCourseGroupModal_GetOrganisationById($id: ID!) {
            getOrganisationById(id: $id) {
              id
              groups {
                edges {
                  node {
                    name
                    id
                  }
                }
              }
            }
          }
        `;
      },

      variables() {
        return { id: this.$user.context.resourceId };
      },

      update(data) {
        return data.getOrganisationById.groups.edges.map(({ node }) => node);
      },
    },
  },
};
</script>

<style></style>
