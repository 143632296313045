import { nonReactiveMembersMixin } from '@/mixins/nonReactiveMembersMixin';
import { upperFirst } from './upper-first';

export const Role = Object.freeze({
  MANAGER: 'MANAGER',
  COACH: 'COACH',
  STUDENT: 'STUDENT',
  ADMIN: 'ADMIN',
});

/**
 * Get a human readable label for the corresponding `Role` member.
 * @param {string} type
 * @returns {string} label
 */
export function getRoleLabel(type, capitalize = false) {
  if (!Role[type]) throw new Error(`Unknown type ${type}`);

  let label;

  switch (type) {
    case Role.MANAGER:
      label = 'beheerder';
      break;
    case Role.COACH:
      label = 'begeleider';
      break;
    case Role.STUDENT:
      label = 'leerling';
      break;
    case Role.ADMIN:
      label = 'administrator';
      break;
  }

  return capitalize ? upperFirst(label) : label;
}

export const roleMixin = Object.freeze({
  mixins: [nonReactiveMembersMixin(() => ({ Role }))],
  methods: { getRoleLabel },
});
