<template>
  <div>
    <sb-loading v-if="loading" />

    <sb-page-header>
      <template v-slot:left> </template>
      <template v-slot:right>
        <!-- <RouterLink
          :to="{
            name: 'AdminSchoolManagementCourseGroupsCourseGroupEdit'
          }"
        >
          <i-button
            type="primary"
            ghost
          >
            Leerling in groep beheren
          </i-button>
        </RouterLink> -->
      </template>
    </sb-page-header>

    <sb-students-table :course-group-id="courseGroupId" :hide-actions="true" />

    <sb-spacer height="200" />
  </div>
</template>

<script>
import SbLoading from '@/components/SbLoading';
import SbPageHeader from '@/components/SbPageHeader';

import SbStudentsTable from '@/components/SbStudentsTable/SbStudentsTable';

export default {
  name: 'OnlineStudents',
  components: {
    SbLoading,
    SbPageHeader,
    SbStudentsTable,
  },
  props: {
    courseGroupId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
};
</script>

<style lang="scss"></style>
