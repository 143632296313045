<template>
  <div class="sb_base-form-layout">
    <i-form
      ref="form"
      class="sb_form"
      :label-width="labelWidth"
      :disabled="readonly"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <slot :mode="mode" />

      <sb-bottom-fixed-buttons
        v-if="!readonly && withBottomBar"
        :with-side-menu-indent="withBottomBarIndent"
      >
        <template #left>
          <i-button size="large" :disabled="loading" @click="handleCancel">
            {{ cancelButtonText }}
          </i-button>

          <i-button
            v-if="$listeners.reset"
            style="margin-left: 10px"
            size="large"
            :disabled="loading"
            @click="$emit('reset')"
          >
            {{ resetButtonText }}
          </i-button>
        </template>

        <template #right>
          <slot name="buttons-right" />
          <i-button
            id="BaseSaveButton"
            type="primary"
            size="large"
            :disabled="loading"
            @click="handleSave"
          >
            {{ saveButtonText }}
          </i-button>
        </template>
      </sb-bottom-fixed-buttons>
    </i-form>

    <sb-bottom-fixed-buttons v-if="readonly && withBottomBar">
      <template #right>
        <i-button
          style="margin-left: 8px"
          size="large"
          type="primary"
          :disabled="loading"
          @click="
            mode = 'edit';
            $emit('edit');
          "
        >
          Bewerken
        </i-button>
      </template>
    </sb-bottom-fixed-buttons>
  </div>
</template>

<script>
import SbBottomFixedButtons from './SbBottomFixedButtons.vue';
export default {
  components: { SbBottomFixedButtons },

  props: {
    labelWidth: { type: Number, default: 160 },

    promise: {
      type: Promise,
      default: undefined,
    },

    autoValidate: {
      type: Boolean,
      default: true,
    },

    saveButtonText: {
      type: String,
      default: 'Opslaan',
    },

    cancelButtonText: {
      type: String,
      default: 'Annuleren',
    },

    resetButtonText: {
      type: String,
      default: 'Herstellen',
    },

    successMessage: {
      type: String,
      default: 'Wijzigingen opgeslagen',
    },

    errorMessage: {
      type: String,
      default: 'Er ging iets mis',
    },

    displayModeOnSubmit: {
      type: Boolean,
      default: true,
    },

    displayModeOnCancel: {
      type: Boolean,
      default: true,
    },

    defaultMode: {
      type: String,
      default: 'readonly',
      validator: (value) => ['edit', 'readonly'].includes(value),
    },

    withBottomBar: {
      type: Boolean,
      default: true,
    },

    withBottomBarIndent: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return { mode: this.defaultMode, loading: false };
  },

  computed: {
    readonly() {
      return this.mode === 'readonly';
    },

    form() {
      return this.$refs.form;
    },
  },

  watch: {
    promise(promise, prevPromise) {
      if (!promise || promise === prevPromise) return;
      this.loading = true;
      promise.then(this.handlePromiseSuccess);
      promise.catch(this.handlePromiseReject);
    },

    mode(value) {
      this.$emit('mode-change', value);
    },
  },

  methods: {
    async handleSave() {
      const valid =
        this.form.rules && this.autoValidate
          ? await this.form.validate()
          : true;

      if (!valid) return this.$Message.error('Invoer is incorrect');

      this.$emit('submit');

      if (!this.promise && this.displayModeOnSubmit) {
        this.mode = 'readonly';
      }
    },

    handleCancel() {
      if (this.displayModeOnCancel) this.mode = 'readonly';
      this.$emit('cancel');
    },

    handlePromiseSuccess() {
      this.loading = false;
      this.$Message.success(this.successMessage);
      this.mode = 'readonly';
      this.$emit('promise-resolve');
    },

    handlePromiseReject() {
      this.loading = false;
      this.$Message.error(this.errorMessage);
      this.$emit('promise-reject');
    },
  },
};
</script>

<style lang="scss" scoped>
.sb_form {
  max-width: 1050px;
}
</style>

<style lang="scss" scoped>
.sb_base-form-layout {
  padding-bottom: 120px;
}
</style>
