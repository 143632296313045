<template>
  <div>
    <sb-call-out
      v-if="
        $user.role === 'COACH' &&
        !$apollo.queries.groups.loading &&
        groups.length === 0
      "
    >
      <template slot="left"> ℹ️ </template>
      <template slot="right">
        Er zijn nog geen groepen om een leerling aan toe te voegen. Vraag de
        schoolbeheerder om een groep aan te maken.
      </template>
    </sb-call-out>

    <sb-user-form
      v-else
      ref="form"
      :validators="validationRules"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <template v-slot:default="{ formData }">
        <sb-create-group-modal
          v-model="showCreateGroupModal"
          @created="handleCreated"
        />

        <sb-divider id="TypeReaderInput" title="Zelfredzaamheid" />

        <form-item label="Soort lezer" prop="zelfredzaamheid">
          <sb-info>
            <p>
              Soort lezer. Redzaamheidslezer: Een lezer die eerst een focus
              heeft op nauwkeurig en vloeiend lezen in plaats van snel lezen.
              Tempolezer: Een lezer die een focus heeft op snel leren lezen.
            </p>
          </sb-info>

          <sb-zelfredzaamheid-select
            v-model="formData.zelfredzaamheid"
            :data-value-reference="formData.zelfredzaamheid"
          />
        </form-item>

        <sb-divider title="Schoolinformatie" />

        <form-item
          v-for="(organisation, i) in formData.organisations"
          :key="organisation.id"
          :label="i === 0 ? 'School' : ''"
        >
          {{ organisation.name }}
        </form-item>

        <sb-course-group-select
          :selected-group-id="
            formData.followingGroupsIds[0] ? formData.followingGroupsIds[0] : ''
          "
          :disabled-fields="disabledFields"
          @open="() => (showCreateGroupModal = true)"
          @onchange="
            (courseGroupId) => {
              formData.followingGroupsIds = [courseGroupId];
            }
          "
        />

        <form-item label="Educatiegroep" prop="classYear">
          <i-select
            v-model="formData.classYear"
            placeholder="Selecteer een groep"
            :data-value-reference="formData.classYear"
          >
            <i-option
              v-for="_type in EducationGroup"
              :key="_type"
              :value="_type"
            >
              {{ getEducationGroupLabel(_type) }}
            </i-option>
          </i-select>
        </form-item>

        <form-item label="Leerlingnummer" prop="studentNumber">
          <i-input
            v-model="formData.studentNumber"
            placeholder="Leerlingnummer"
          />
        </form-item>

        <form-item label="Notities">
          <i-input
            id="OtherNotes"
            v-model="formData.notes"
            class="notes"
            type="textarea"
            placeholder="Evt notities"
            :autosize="{ minRows: 4, maxRows: 10 }"
          />
        </form-item>

        <template>
          <sb-divider v-if="formData.followingGroups" title="Coach(es)" />

          <template v-if="formData.followingGroups">
            <sb-list-item
              v-for="(name, index) in toCoachArray(formData.followingGroups)"
              :key="`coach-${index}`"
              :label="name"
            />
          </template>
        </template>
      </template>
    </sb-user-form>
  </div>
</template>

<script>
import SbCourseGroupSelect from '@/components/SbCourseGroupSelect/SbCourseGroupSelect.vue';
import SbUserForm from '@/components/SbUserForm/SbUserForm.vue';
import { toCoachArray } from '@/lib/data-transformers/to-coach-string';
import { educationGroupMixin } from '@/lib/education-group';
import { zelfredzaamheidMixin } from '@/lib/zelfredzaamheid';
import { changeLoggerMixin } from '@/mixins/changeLogger';
import gql from 'graphql-tag';
import SbCallOut from '../SbCallOut.vue';
import SbListItem from '../SbListItem.vue';
import SbZelfredzaamheidSelect from '../SbZelfredzaamheidSelect.vue';
import SbCreateGroupModal from '../SbUserForm/SbCreateGroupModal.vue';
import { validationRules } from './validation-rules';
import { useIsSuggestionEnabledForSchool } from '@/graphql/hooks/useIsSuggestionEnabledForSchool';

export default {
  name: 'SbStudentForm',

  components: {
    SbUserForm,
    SbCallOut,
    SbZelfredzaamheidSelect,
    SbCreateGroupModal,
    SbCourseGroupSelect,
    SbListItem,
  },

  mixins: [
    zelfredzaamheidMixin,
    educationGroupMixin,
    useIsSuggestionEnabledForSchool,
    changeLoggerMixin(['formData']),
  ],

  props: {
    value: {
      type: Object,
      default: undefined,
    },
    disabledFields: {
      type: Array,
      default: () => [],
    },
  },

  data: () => ({ showCreateGroupModal: false }),

  computed: {
    validationRules() {
      return {
        ...(this.$user.role === 'COACH' && validationRules),
      };
    },
  },

  methods: {
    toCoachArray,
    async handleCreated(createdGroup) {
      this.$refs.form.formData.followingGroupsIds.splice(0, 1, createdGroup.id);
    },
  },

  apollo: {
    defaultZelfredzaamheid: {
      fetchPolicy: 'network-only',

      skip() {
        return !this.$route.params.resourceId;
      },

      variables() {
        return {
          id: this.$route.params.resourceId,
        };
      },

      update(data) {
        if (
          this.$attrs['default-mode'] === 'readonly' ||
          !!this.$refs.form.formdata?.zelfredzaamheid
        ) {
          return;
        }

        const zelfredzaamheid =
          this.$user.role === 'COACH'
            ? data.getOrganisationDefaultZelfredzaamheid
            : data.getOrganisationById?.defaultZelfredzaamheid;
        this.$refs.form.formData.zelfredzaamheid = zelfredzaamheid;

        return zelfredzaamheid;
      },

      query() {
        if (this.$user.role === 'COACH') {
          return gql`
            query StudentForm_GetDefaultZelfredzaamheid($id: ID!) {
              getOrganisationDefaultZelfredzaamheid(id: $id)
            }
          `;
        }

        return gql`
          query StudentForm_GetDefaultZelfredzaamheid($id: ID!) {
            getOrganisationById(id: $id) {
              id
              ... on School {
                defaultZelfredzaamheid
              }
            }
          }
        `;
      },
    },
    groups: {
      fetchPolicy: 'network-only',

      query() {
        if (this.$user.role === 'COACH') {
          return gql`
            query StudentForm_GetCoachingGroups($id: ID!) {
              getUserById(id: $id) {
                id
                coachingGroups {
                  id
                  name
                }
              }
            }
          `;
        }

        return gql`
          query StudentForm_GetOrganisationGroups($id: ID!, $first: Int) {
            getOrganisationById(id: $id) {
              id
              groups(first: $first) {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
            }
          }
        `;
      },

      variables() {
        if (this.$user.role === 'COACH') {
          return {
            id: this.$user.id,
          };
        }

        return {
          id: this.$route.params.resourceId,
        };
      },

      update(data) {
        if (this.$user.role === 'COACH') {
          return data.getUserById.coachingGroups;
        }
        return (
          data.getOrganisationById?.groups?.edges?.map((edge) => edge.node) ??
          []
        );
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.group-inputs {
  display: grid;
  gap: 1rem;
  grid-auto-columns: max-content;
  grid-auto-flow: column;
  align-items: center;
}
</style>
