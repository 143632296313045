<template>
  <modal
    title="Nieuwe cursusgroep"
    :value="value"
    @input="$emit('input', $event)"
    @on-ok="createGroup"
  >
    Voer een groepnaam in. Je kan deze later nog wijzigen.
    <sb-spacer height="10" />
    <i-input v-model="name" placeholder="Naam groep" />
  </modal>
</template>

<script>
import gql from 'graphql-tag';
export default {
  props: { value: { type: Boolean, default: false } },
  data: () => ({ name: '' }),
  methods: {
    async createGroup() {
      try {
        const { data, errors } = await this.$apollo.mutate({
          variables: {
            input: {
              name: this.name,
              schoolId: this.$route.params.resourceId,
            },
          },
          mutation: gql`
            mutation CreateGroupModal_CreateGroup($input: CreateGroupInput!) {
              createGroup(input: $input) {
                id
                name
              }
            }
          `,
        });

        if (errors) {
          throw new Error(errors.map((e) => e.message).join('\n'));
        }

        this.$emit('created', data.createGroup);
        setTimeout(() => (this.name = ''), 300);
      } catch (error) {
        console.error(error);
        this.$showGenericError();
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
