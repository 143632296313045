<template>
  <div>
    <sb-gql-table
      id="GroupsTable"
      ref="table"
      :config="tableConfig"
      :query-options="queryOptions"
      :data-path="tableDataPath"
      @cell-click-name="viewGroup"
      @row-action-edit="editGroup"
    >
    </sb-gql-table>
  </div>
</template>

<script>
import CoachGroups from '@/graphql/queries/CoachGroups.gql';
import Groups from '@/graphql/queries/Groups.gql';
import { StringFilter } from '@/lib/gql-filters';
import { nonReactiveMembersMixin } from '@/mixins/nonReactiveMembersMixin';
import SbGqlTable from './SbTable2/SbGqlTable.vue';
import { TableConfig } from './SbTable2/TableConfig';

export default {
  components: {
    SbGqlTable,
    // SbLoading
  },

  mixins: [
    nonReactiveMembersMixin((self) => {
      const tableConfig = new TableConfig({
        rowActions: [['edit', 'Wijzigen']],
        columns: [
          {
            key: 'name',
            header: 'Naam',
            width: 200,
            fixed: 'left',
            action: true,
            filter: 'search',
            sortable: true,
            sort: 'ascending',
            meta: { gqlFilter: StringFilter },
          },
          {
            key: 'coaches',
            header: 'Begeleider(s)',
            data: (row) =>
              row.coaches?.edges.map(({ node }) => node.fullName).join(', ') ??
              '-',
          },
          {
            key: 'coaches',
            header: 'Aantal begeleiders',
            data: (row) => row.coaches?.totalCount ?? 0,
          },
          {
            key: 'coaches',
            header: 'Aantal leerlingen',
            data: (row) => row.students?.totalCount ?? 0,
          },
          {
            key: 'createdAt',
            header: 'Aangemaakt',
            sortable: true,
            width: 200,
            fixed: 'right',
            data: (row) =>
              row.createdAt
                ? new Date(row.createdAt).toLocaleDateString()
                : '-',
          },
        ],
      });

      return { tableConfig };
    }),
  ],

  props: {
    organisationId: {
      type: String,
      default: null,
    },
  },

  data() {
    return { loading: false };
  },

  computed: {
    tableDataPath() {
      return this.$user.role === 'COACH'
        ? 'getUserById.paginatedCoachingGroups'
        : 'getOrganisationById.groups';
    },
  },

  methods: {
    queryOptions({
      first,
      last,
      after,
      before,
      filter,
      filters,
      orderBy,
      pagination,
    }) {
      const isCoach = this.$user.role === 'COACH';
      const query = isCoach ? CoachGroups : Groups;
      const id = isCoach ? this.$user.token.sub : this.organisationId;

      const variables = {
        first,
        last,
        after,
        before,
        filter,
        orderBy,
        pagination,
        id,
      };

      return { query, variables };
    },

    viewGroup({ id }) {
      this.$router.push({
        name: 'ResourceManagementCourseGroupsCourseGroup',
        params: {
          resourceType: 'school',
          resourceId: this.organisationId,
          courseGroupId: id,
        },
      });
    },

    editGroup({ id }) {
      this.$router.push({
        params: { courseGroupId: id },
        name: 'ResourceManagementCourseGroupsCourseGroupEdit',
      });
    },
  },
};
</script>

<style></style>
