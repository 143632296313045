<template>
  <div>
    <sb-gql-table
      id="CoachesTable"
      ref="table"
      :config="tableConfig"
      :query-options="queryOptions"
      :data-path="tableDataPath"
      @cell-click-username="viewCoach"
      @row-action-edit="editCoach"
      @row-action-edit-password="editPassword"
    >
      <template slot="column-username" slot-scope="{ content }">
        <span class="username">{{ content }}</span>
      </template>
    </sb-gql-table>
  </div>
</template>

<script>
import OrganisationCoaches from '@/graphql/queries/OrganisationCoaches.gql';
import GroupCoaches from '@/graphql/queries/GroupCoaches.gql';
import Coaches from '@/graphql/queries/Coaches.gql';
import SbGqlTable from './SbTable2/SbGqlTable.vue';
import { nonReactiveMembersMixin } from '@/mixins/nonReactiveMembersMixin';
import { TableConfig } from './SbTable2/TableConfig';
import { DateTimeFilter, StringFilter } from '@/lib/gql-filters';
import { coachTypeMixin } from '@/lib/coach-type';

export default {
  components: {
    SbGqlTable,
  },

  mixins: [
    coachTypeMixin,
    nonReactiveMembersMixin((self) => {
      const tableConfig = new TableConfig({
        rowActions: [
          ['edit', 'Gegevens aanpassen'],
          ['edit-password', 'Wachtwoord veranderen'],
        ],
        columns: [
          {
            key: 'username',
            header: 'Gebruikersnaam',
            width: 250,
            fixed: 'left',
            action: true,
            sortable: true,
            filter: 'search',
            meta: { gqlFilter: StringFilter },
          },
          {
            key: 'fullName',
            header: 'Naam',
            filter: 'search',
            sortable: true,
            sort: 'ascending',
            meta: { gqlFilter: StringFilter },
          },
          {
            key: 'coachType',
            header: 'Type',
            data: (row) =>
              row.coachType ? self.getCoachTypeLabel(row.coachType) : '-',
          },
          {
            key: 'coachingGroups',
            header: 'Groep(en)',
            data: (row) =>
              row.coachingGroups.map((node) => node.name).join(', '),
          },
          {
            key: 'email',
            header: 'E-mail',
            filter: 'search',
            meta: { gqlFilter: StringFilter },
          },
          {
            key: 'lastLogin',
            header: 'Laatste inlogdatum',
            data: (row) =>
              row.lastLogin
                ? new Date(row.lastLogin).toLocaleDateString()
                : '-',
          },
          {
            key: 'birthDate',
            header: 'Geboortedatum',
            filter: 'date',
            filterPlaceholder: 'dd-mm-jjjj',
            sortable: true,
            width: 300,
            meta: { gqlFilter: DateTimeFilter },
            data: (row) =>
              row.birthdate
                ? new Date(row.birthdate).toLocaleDateString()
                : '-',
          },
          // columns for admin context
          ...(self.$user.context.isAdmin
            ? [
                {
                  key: 'organisations',
                  header: 'Organisatie',
                  data: (row) => row.organisations[0]?.name ?? '-',
                },
              ]
            : []),
        ],
      });
      return { tableConfig };
    }),
  ],

  props: {
    organisationId: {
      type: String,
      default: null,
    },
    courseGroupId: {
      type: String,
      default: null,
    },
    hideActions: {
      type: Boolean,
      default: false,
    },
    nested: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    tableDataPath() {
      if (this.$user.context.resourceType === 'ADMIN') return 'users';
      if (this.courseGroupId) return 'getGroupById.coaches';
      return 'getOrganisationById.coaches';
    },
  },

  methods: {
    queryOptions({ first, last, after, before, filter, orderBy }) {
      let query;
      const variables = {
        first,
        last,
        after,
        before,
        orderBy,
        filter: {
          ...filter,
          role:
            this.$user.context.resourceType === 'ADMIN' ? 'COACH' : undefined,
        },
        ...(this.organisationId && {
          id: this.organisationId,
        }),
        ...(this.courseGroupId && {
          id: this.courseGroupId,
        }),
      };

      // if admin
      if (this.$user.context.resourceType === 'ADMIN') {
        query = Coaches;
        this.queryName = 'Coaches';
      }

      // if in group view
      else if (this.courseGroupId) {
        query = GroupCoaches;
        this.queryName = 'GroupCoaches';
      }

      // if viewing as organisation
      else {
        query = OrganisationCoaches;
        this.queryName = 'OrganisationCoaches';
      }

      return { query, variables };
    },

    viewCoach(coach) {
      this.$router.push({
        name: 'ResourceManagementCoachesCoach',
        params: {
          resourceType: 'school',
          resourceId: coach.organisations?.[0]?.id,
          coachId: coach.id,
        },
      });
    },

    editCoach({ id }) {
      this.$router.push({
        name: 'ResourceManagementCoachesCoachEdit',
        params: {
          coachId: id,
        },
      });
    },

    editPassword({ id }) {
      this.$router.push({
        name: 'ResourceManagementCoachesCoachEditPassword',
        params: {
          coachId: id,
        },
      });
    },
  },
};
</script>

<style scoped>
.username {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  display: inline-block;
}
</style>
