<template>
  <sb-base-form-layout
    ref="baseForm"
    :default-mode="$attrs['default-mode'] || (createMode ? 'edit' : 'readonly')"
    :display-mode-on-cancel="editMode"
    :display-mode-on-submit="preventDisplayModeOnSubmit ? false : editMode"
    :rules="validationRules"
    :model="formData"
    :promise="promise"
    :with-bottom-bar="$attrs['with-bottom-bar']"
    v-bind="$attrs"
    @cancel="handleCancel"
    @submit="handleSubmit"
    @reset="handleReset"
  >
    <sb-divider title="Persoonlijke informatie" />

    <form-item id="FirstnameInput" label="Voornaam" prop="firstName">
      <i-input v-model="formData.firstName" placeholder="Voornaam" />
    </form-item>

    <form-item label="Tussenvoegsel">
      <i-input v-model="formData.prefix" placeholder="Tussenvoegsel" />
    </form-item>

    <form-item label="Achternaam" prop="lastName">
      <i-input v-model="formData.lastName" placeholder="Achternaam" />
    </form-item>

    <form-item label="Geboortedatum">
      <date-picker
        type="date"
        placeholder="dd-mm-jjjj"
        style="width: 300px"
        format="dd-MM-yyyy"
        :start-date="new Date(2010, 0, 1)"
        :value="birthDate"
        @input="formData.birthDate = $event"
      />
    </form-item>

    <form-item label="Geslacht">
      <radio-group v-model="formData.gender">
        <radio v-for="_gender in Gender" :key="_gender" :label="_gender">
          {{ getGenderLabel(_gender) }}
        </radio>
      </radio-group>
    </form-item>

    <form-item v-if="!createMode" label="Gebruikersnaam" prop="username">
      <i-input
        v-model="formData.username"
        placeholder="Gebruikersnaam"
        class="lower-case"
        readonly
      />
    </form-item>

    <form-item label="E-mailadres" prop="email">
      <i-input
        v-model="formData.email"
        placeholder="E-mailadres"
        type="email"
      />
    </form-item>

    <form-item label="Secundair e-mailadres">
      <i-input
        v-model="formData.secondaryEmails[0]"
        placeholder="E-mailadres"
        type="email"
      />
    </form-item>

    <form-item v-if="createMode" label="Direct gebruikersnaam instellen?">
      <sb-info>
        <p>
          Een gebruikersnaam wordt automatisch gegenereerd, tenzij hier
          opgegeven.
        </p>
      </sb-info>
      <i-switch
        v-model="enableUserNameInput"
        @input="handleUserNameInputToggle"
      >
        <span slot="open">Ja</span>
        <span slot="close">Nee</span>
      </i-switch>
    </form-item>

    <form-item
      v-if="enableUserNameInput"
      ref="usernameInput"
      label="Gebruikersnaam"
      prop="username"
    >
      <i-input
        v-model="formData.username"
        placeholder="Gebruikersnaam"
        @input="handleUsernameChange"
      />
    </form-item>

    <form-item
      v-if="createMode"
      id="PasswordInput"
      label="Direct wachtwoord instellen?"
    >
      <sb-info>
        <p>
          Als je direct het wachtwoord instelt, krijgt de gebruiker geen e-mail
          (ook niet van zijn / haar gebruikersnaam).
        </p>
      </sb-info>
      <i-switch
        v-model="registerWithPassword"
        :disabled="!enableUserNameInput || !formData.username"
      >
        <span slot="open">Ja</span>
        <span slot="close">Nee</span>
      </i-switch>
    </form-item>

    <div v-if="registerWithPassword" style="margin-left: 24px">
      <form-item label="Wachtwoord" prop="password">
        <i-input
          v-model="formData.password"
          type="password"
          placeholder="Wachtwoord"
        />
      </form-item>

      <form-item label="Herhaling Wachtwoord" prop="passwordConfirmation">
        <i-input
          v-model="formData.passwordConfirmation"
          type="password"
          placeholder="Wachtwoord"
        />
      </form-item>
    </div>

    <slot :formData="formData" :editMode="editMode" :createMode="createMode" />

    <template v-if="!createMode">
      <sb-divider id="OtherInformation" title="Overige informatie" />

      <form-item label="Aangemaakt op:">
        {{ formData.createdAt | date(true) }}
      </form-item>
    </template>
  </sb-base-form-layout>
</template>

<script>
import { validationRules } from './validation-rules';
import { genderMixin } from '@/lib/gender';
import { roleMixin } from '@/lib/role';
import UpdateUser from '@/graphql/mutations/UpdateUser.gql';
import CreateUser from '@/graphql/mutations/CreateUser.gql';
import SbBaseFormLayout from '../SbBaseFormLayout.vue';
import { formDataMixin } from '@/mixins/formDataMixin';
import { changeLoggerMixin } from '@/mixins/changeLogger';

export default {
  components: { SbBaseFormLayout },

  mixins: [
    genderMixin,
    roleMixin,
    formDataMixin(
      Object.freeze({
        birthDate: undefined,
        classYear: undefined,
        followingGroupsIds: [],
        followingGroups: [],
        coachingGroupsIds: [],
        email: undefined,
        firstName: undefined,
        gender: undefined,
        isTemporaryPassword: false,
        lastName: undefined,
        notes: undefined,
        password: undefined,
        passwordConfirmation: undefined,
        prefix: undefined,
        secondaryEmails: [],
        studentNumber: undefined,
        username: undefined,
        zelfredzaamheid: null,
        coachType: undefined,
        cardSuggestionsEnabled: false,
        cardSettings: {
          fontType: 'NUNITO',
          fontSize: 0,
          letterSpacing: 1,
          lineHeight: 0,
          showIllustrations: true,
          wordSpacing: 0,
          cursorSpeed: 1,
          focusMode: false,
        },
      }),
    ),
    changeLoggerMixin(['formData'], { deep: true }),
  ],

  props: {
    validators: {
      type: Object,
      default: undefined,
    },

    value: {
      type: Object,
      default: undefined,
    },

    organisationId: {
      type: String,
      default: undefined,
    },

    role: {
      type: String,
      default: undefined,
    },

    forceCreateMode: {
      type: Boolean,
      default: false,
    },

    navigateBackOnSubmit: {
      type: Boolean,
      default: true,
    },

    preventDisplayModeOnSubmit: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    const validatePassCheck = (rule, value, callback) => {
      if (!this.registerWithPassword) return callback();
      if (value === '') {
        callback(new Error('Vul het wachtwoord tweemaal in'));
      } else if (value !== this.formData.password) {
        callback(new Error('De ingevoerde wachtwoorden komen niet overeen'));
      }
      return callback();
    };

    return {
      mounted: false,
      enableUserNameInput: false,
      registerWithPassword: false,
      doEmailCredentials: true,
      successMessage: '',
      promise: undefined,
      validationRules: {
        ...validationRules,
        ...this.validators,
        passwordConfirmation: [
          {
            validator: validatePassCheck,
            trigger: 'change',
          },
        ],
      },
    };
  },

  computed: {
    birthDate() {
      const { birthDate } = this.formData;
      return birthDate ? new Date(birthDate) : undefined;
    },

    createMode() {
      return !this.defaultData || this.forceCreateMode;
    },

    editMode() {
      return !!this.defaultData;
    },
  },

  watch: {
    'formData.followingGroups': {
      handler(value) {
        this.formData.followingGroupsIds = value?.map((e) => e.id) || [];
      },
      immediate: true,
    },
  },

  mounted() {
    this.mounted = true;

    this.$watch(
      'formData.username',
      (username) => {
        this.formData.username = username?.toLowerCase();
      },
      { immediate: true },
    );
  },

  methods: {
    handleUsernameChange(value) {
      if (!value) this.handleUserNameInputToggle(false);
    },

    handleUserNameInputToggle(toggled) {
      if (toggled) return;
      this.registerWithPassword = false;
      this.formData.username = undefined;
      this.formData.password = undefined;
      this.formData.passwordConfirmation = undefined;
    },

    handleCancel() {
      this.resetFormData();
      if (this.createMode) this.$router.back();
    },

    handleReset() {
      this.resetFormData();
    },

    async handleSubmit() {
      const roleLabel = this.getRoleLabel(this.role);
      let result;

      try {
        if (this.createMode) {
          result = (
            await (this.promise = this.$apollo.mutate({
              mutation: CreateUser,
              variables: {
                input: {
                  organisation: {
                    id: this.organisationId,
                    role: this.role,
                  },
                  ...this.createInputData(CREATE_INPUT_FIELDS),
                },
              },
            }))
          ).data;

          this.successMessage = `De ${roleLabel} is succesvol aangemaakt. De gebruikersnaam is ${result.createUser.username}`;
          this.$emit('submit', result.createUser);
          if (this.navigateBackOnSubmit) this.$router.navigateBack();
        } else if (this.editMode) {
          result = await (this.promise = this.$apollo.mutate({
            mutation: UpdateUser,
            variables: {
              input: {
                id: this.formData.id,
                data: this.createInputData(UPDATE_INPUT_FIELDS),
              },
            },
          })).data;

          this.successMessage = `Wijzigingen aan ${roleLabel} zijn opgeslagen`;
        }
      } catch (error) {
        if (
          error?.graphQLErrors?.find(({ extensions }) =>
            extensions.exception?.response?.message?.includes(
              'Username already exists',
            ),
          )
        ) {
          const username = this.formData.username;
          const suggestions = [
            [
              this.formData.firstName,
              this.formData.prefix,
              this.formData.lastName,
            ]
              .filter(Boolean)
              .join('.'),
            this.formData.birthDate
              ? [
                  username,
                  new Date(this.formData.birthDate).getFullYear(),
                ].join('.')
              : null,
            [username, new Date().getDate()].join('-'),
          ]
            .filter(Boolean)
            .map((e) => `"${e}"`)
            .join(', ');

          this.validationRules.username = [
            {
              message: `Gebruikersnaam "${username}" is al bezet. \nSuggesties: ${suggestions}`,
              validator(rule, value, callback) {
                if (value === username) {
                  return callback(new Error(rule.message));
                }
                return callback();
              },
            },
          ];

          this.$refs.usernameInput.$el.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
          this.$refs.baseForm.form.validate();
        }
      }
    },

    createInputData(fields) {
      const input = { ...this.formData, ...this.value };
      return fields.reduce((acc, field) => {
        if (input[field] == undefined) return acc;

        if (field === 'birthDate' && input[field]) {
          acc[field] = new Date(input[field]).toDateString();
          return acc;
        }

        if (field === 'secondaryEmails') {
          acc[field] = input[field].filter((e) => Boolean(e));
          return acc;
        }

        if (field === 'followingGroups') {
          const connect = Array.from(new Set(this.formData.followingGroupsIds));
          const disconnect = Array.from(
            new Set(
              this.formData.followingGroups
                .map((e) => e.id)
                .filter((id) => !connect.includes(id)),
            ),
          );

          if (!connect.length && !disconnect.length) return acc;

          acc.followingGroups = { connect, disconnect };
          return acc;
        }

        acc[field] = input[field];

        if (acc.username && acc.password) {
          acc['doNotSendEmail'] = true;
        }
        return acc;
      }, {});
    },
  },
};

const CREATE_INPUT_FIELDS = [
  'availableCardsIds',
  'birthDate',
  'cardSettings',
  'classYear',
  'coachingGroupsIds',
  'coachType',
  'doNotSendEmail',
  'email',
  'firstName',
  'followingGroupsIds',
  'gender',
  'isTemporaryPassword',
  'lastName',
  'notes',
  'organisation',
  'password',
  'prefix',
  'secondaryEmails',
  'studentNumber',
  'username',
  'zelfredzaamheid',
  'cardSuggestionsEnabled',
];

const UPDATE_INPUT_FIELDS = [
  'birthDate',
  'cardSettings',
  'classYear',
  'coachType',
  'email',
  'firstName',
  'followingGroups',
  'gender',
  'lastName',
  'notes',
  'organisation',
  'prefix',
  'secondaryEmails',
  'studentNumber',
  'zelfredzaamheid',
  'cardSuggestionsEnabled',
];
</script>
