<template>
  <div>
    <sb-loading v-if="$apollo.queries.courseGroup.loading" />
    <!-- <Breadcrumb v-if="courseGroup">
      <BreadcrumbItem :to="{name: 'ResourceManagementCourseGroups'}">
        Cursusgroepen
      </BreadcrumbItem>
      <BreadcrumbItem>
        {{ courseGroup.name }}
      </BreadcrumbItem>
      <BreadcrumbItem />
    </Breadcrumb> -->

    <sb-page-header v-if="courseGroup">
      <template v-slot:left>
        <h1>{{ courseGroup.name }} <small>Groep</small></h1>
      </template>
      <template v-slot:right>
        <dropdown
          v-if="['ADMIN', 'MANAGER'].includes($user.role)"
          placement="bottom-end"
        >
          <i-button type="primary" ghost>
            Acties
            <icon type="md-more" />
          </i-button>

          <dropdown-menu slot="list" style="width: 220px">
            <sb-dropdown-button
              style="color: red"
              @click.native="confirmArchiveGroup"
            >
              Groep verwijderen
            </sb-dropdown-button>
          </dropdown-menu>
        </dropdown>
      </template>
    </sb-page-header>

    <!-- <SbTabbedPage /> -->
    <sb-menu-tabs :routes="routes" :current-route="$route" />
    <router-view type="courseGroup" :data="courseGroup" />
  </div>
</template>

<script>
import SbLoading from '@/components/SbLoading';
import SbMenuTabs from '@/components/SbMenuTabs';

import SbPageHeader from '@/components/SbPageHeader';

import { findRouteByName } from '@/lib/route-helper';

import GroupById from '@/graphql/queries/GroupById.gql';
import DeleteGroup from '@/graphql/mutations/DeleteGroup.gql';

export default {
  components: {
    SbPageHeader,
    SbLoading,
    SbMenuTabs,
  },
  props: {
    courseGroupId: {
      type: String,
      required: true,
    },
  },

  computed: {
    routes() {
      return findRouteByName('ResourceManagementCourseGroupsCourseGroup')
        .children;
    },
  },

  apollo: {
    courseGroup: {
      query: GroupById,
      fetchPolicy: 'network-only',
      update(data) {
        return data.getGroupById;
      },
      variables() {
        console.log('vari', this.courseGroupId);
        return {
          id: this.courseGroupId,
        };
      },
    },
  },
  methods: {
    async deleteGroup() {
      // this.loading = true;
      await this.$apollo.mutate({
        mutation: DeleteGroup,
        variables: {
          id: this.courseGroupId,
        },
      });
      setTimeout(() => {
        // this.loading = false;
        // this.user = null;
        this.$Modal.info({
          title: 'Gelukt',
          content: 'De groep is succesvol verwijderd',
          onOk: () => {
            this.$router.navigateBack();
          },
        });
      }, 500);
    },
    confirmArchiveGroup() {
      this.$Modal.confirm({
        title: 'Let op!',
        content: 'Weet je zeker dat je deze groep wilt verwijderen?',
        closable: true,
        onOk: () => {
          this.deleteGroup();
        },
      });
    },
  },
};
</script>

<style></style>
