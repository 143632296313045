<template>
  <sb-user-form
    ref="form"
    v-bind="$attrs"
    :role="Role.COACH"
    :organisation-id="organisationId"
    :default-data="defaultData"
    v-on="$listeners"
  >
    <template v-slot:default="{ formData }">
      <form-item label="Type begeleider" prop="coachType">
        <radio-group v-model="formData.coachType">
          <radio
            v-for="coachType in CoachType"
            :key="coachType"
            :label="coachType"
          >
            {{ getCoachTypeLabel(coachType) }}
          </radio>
        </radio-group>
      </form-item>
    </template>
  </sb-user-form>
</template>

<script>
import { roleMixin } from '@/lib/role';
import { coachTypeMixin } from '@/lib/coach-type';
import SbUserForm from '@/components/SbUserForm/SbUserForm.vue';

export default {
  components: {
    SbUserForm,
  },

  mixins: [roleMixin, coachTypeMixin],

  props: {
    organisationId: { type: String, required: true },
    defaultData: { type: Object, default: () => ({}) },
  },
};
</script>
