<template>
  <i-select
    v-bind="$attrs"
    :value="value"
    v-on="$listeners"
    @input="$emit('input', $event)"
  >
    <i-option v-for="_type in Zelfredzaamheid" :key="_type" :value="_type">{{
      getZelfredzaamheidLabel(_type)
    }}</i-option>
  </i-select>
</template>

<script>
import { zelfredzaamheidMixin } from '@/lib/zelfredzaamheid';
export default {
  mixins: [zelfredzaamheidMixin],

  props: {
    value: {
      type: String,
      default: undefined,
    },
  },
};
</script>
