export const validationRules = {
  firstName: [
    {
      required: true,
      message: 'Voer een voornaam in',
      trigger: 'blur',
    },
  ],

  lastName: [
    {
      required: true,
      message: 'Voer een achternaam in',
      trigger: 'blur',
    },
  ],

  email: [
    {
      required: true,
      message: 'Vul een e-mailadres in',
      trigger: 'change',
    },
  ],

  password: [
    {
      required: false,
      message: 'Vul een wachtwoord in.',
      trigger: 'blur',
    },

    {
      type: 'string',
      min: 8,
      message: 'Het wachtwoord moet minstens 8 karakters zijn',
      trigger: 'blur',
    },
  ],
};
