export const Gender = {
  FEMALE: 'FEMALE',
  MALE: 'MALE',
  OTHER: 'OTHER',
};

/**
 * Get a human readable label for the corresponding `Gender` member.
 * @param {string} type
 * @returns {string} gender label
 */
export function getGenderLabel(type) {
  switch (type) {
    case Gender.FEMALE:
      return 'Vrouw';
    case Gender.MALE:
      return 'Man';
    case Gender.OTHER:
      return 'Anders';
    default:
      throw new Error(`Unknown type ${type}`);
  }
}

export const genderMixin = {
  data: () => ({ Gender }),
  methods: { getGenderLabel },
};
