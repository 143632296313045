<template>
  <div>
    <sb-page-header>
      <template v-slot:left>
        <h1>Groepen</h1>
      </template>
      <template v-slot:right>
        <router-link
          v-if="
            ['ADMIN', 'MANAGER'].includes($user.role) && !$user.context.isAdmin
          "
          :to="{ name: 'ResourceManagementCourseGroupAdd' }"
        >
          <i-button id="AddGroupButton" type="primary" ghost>
            Groep toevoegen

            <icon type="md-add" />
          </i-button>
        </router-link>
      </template>
    </sb-page-header>

    <sb-course-groups-table :organisation-id="organisationId || resourceId" />
  </div>
</template>

<script>
import SbPageHeader from '@/components/SbPageHeader';
import SbCourseGroupsTable from '@/components/SbCourseGroupsTable';
import { mapActions } from 'vuex';

export default {
  components: {
    SbPageHeader,
    SbCourseGroupsTable,
  },

  props: {
    organisationId: {
      type: String,
      default: null,
    },
    resourceId: {
      type: String,
      default: null,
    },
  },

  mounted() {
    const currentTourKey = 'MANAGER_GROUP';
    this.enqueueTour(currentTourKey);
  },

  methods: {
    ...mapActions('onboarding', ['enqueueTour']),
  },
};
</script>

<style></style>
