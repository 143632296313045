<template>
  <div>
    <sb-loading v-if="loading" />

    <sb-page-header>
      <template v-slot:left> </template>
      <template v-slot:right> </template>
    </sb-page-header>

    <sb-coaches-table
      :hide-actions="true"
      :nested="true"
      :course-group-id="courseGroupId"
    />

    <sb-spacer height="200" />
  </div>
</template>

<script>
import SbLoading from '@/components/SbLoading';
import SbPageHeader from '@/components/SbPageHeader';

import SbCoachesTable from '@/components/SbCoachesTable';

export default {
  name: 'OnlineStudents',
  components: {
    SbLoading,
    SbPageHeader,
    SbCoachesTable,
  },
  props: {
    courseGroupId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
};
</script>

<style lang="scss"></style>
