export const EducationGroup = {
  GROUP_1: 'GROUP_1',
  GROUP_2: 'GROUP_2',
  GROUP_3: 'GROUP_3',
  GROUP_4: 'GROUP_4',
  GROUP_5: 'GROUP_5',
  GROUP_6: 'GROUP_6',
  GROUP_7: 'GROUP_7',
  GROUP_8: 'GROUP_8',
  OTHER: 'OTHER',
};

/**
 * Get a human readable label for the corresponding `EducationGroup` member.
 * @param {string} type
 * @returns {string} education group label
 */
export function getEducationGroupLabel(type) {
  switch (type) {
    case EducationGroup.GROUP_1:
      return 'Groep 1';
    case EducationGroup.GROUP_2:
      return 'Groep 2';
    case EducationGroup.GROUP_3:
      return 'Groep 3';
    case EducationGroup.GROUP_4:
      return 'Groep 4';
    case EducationGroup.GROUP_5:
      return 'Groep 5';
    case EducationGroup.GROUP_6:
      return 'Groep 6';
    case EducationGroup.GROUP_7:
      return 'Groep 7';
    case EducationGroup.GROUP_8:
      return 'Groep 8';
    case EducationGroup.OTHER:
      return 'Anders';
    default:
      throw new Error(`Unknown type ${type}`);
  }
}

export const educationGroupMixin = {
  data: () => ({ EducationGroup }),
  methods: { getEducationGroupLabel },
};
