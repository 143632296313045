/**
 * Flattens a nested array within an object based on the specified property name.
 * @param {object} obj - The object containing the nested array.
 * @param {string} propertyName - The name of the property to be flattened.
 * @returns {array} - The flattened array.
 */
export function flattenPaginatedType(obj, propertyName) {
  const flattenedArray = [];

  if (!obj || !propertyName) {
    console.error('Invalid object or property name.');
    return flattenedArray;
  }

  if (!obj[propertyName] || !obj[propertyName].edges) {
    console.warn(`Property '${propertyName}' or its edges are missing.`);
    return flattenedArray;
  }

  obj[propertyName].edges.forEach((edge) => {
    if (edge.node && edge.node.__typename) {
      flattenedArray.push(edge.node);
    }
  });

  return flattenedArray;
}
