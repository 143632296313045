<template>
  <form-item
    v-if="
      ['COACH', 'MANAGER'].includes($user.role) ||
      ($user.context.role === 'ADMIN' &&
        $user.context.resourceType === 'SCHOOL')
    "
    label="Cursusgroep"
    prop="followingGroupsIds"
  >
    <sb-info>
      <p v-if="$user.role === 'COACH'">
        Voeg hier de leerling direct toe aan een cursusgroep.
      </p>
      <p v-else>
        Het is optioneel om hier de gebruiker direct toe te voegen aan een
        cursusgroep.
      </p>
    </sb-info>
    <div class="group-inputs">
      <i-select
        id="CourseGroupInput"
        v-model="groupId"
        style="max-width: 300px; min-width: 200px"
        :placeholder="
          $apollo.queries.groups.loading
            ? 'Bezig met ophalen...'
            : 'Kies een groep'
        "
        :required="$user.role === 'COACH'"
        :disabled="
          $apollo.queries.groups.loading ||
          disabledFields.includes('followingGroupsIds')
        "
      >
        <!-- Only allow on create-->
        <i-option v-if="!selectedGroupId" style="opacity: 0.3" :value="''">
          Geen Cursusgroep
        </i-option>

        <i-option v-for="group in groups" :key="group.id" :value="group.id">
          {{ group.name }}
        </i-option>
      </i-select>

      <template
        v-if="
          $user.role === 'MANAGER' &&
          !disabledFields.includes('followingGroupsIds')
        "
      >
        of
        <i-button @click="$emit('open')"> Nieuwe groep </i-button>
      </template>
    </div>
  </form-item>
</template>

<script>
import { coachTypeMixin } from '@/lib/coach-type';
import { roleMixin } from '@/lib/role';
import gql from 'graphql-tag';

export default {
  name: 'SbCourseGroupSelect',

  mixins: [roleMixin, coachTypeMixin],

  props: {
    /**
     * The id of the selected group.
     */
    selectedGroupId: {
      type: String,
      default: null,
      required: false,
    },

    disabledFields: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      groupId: this.selectedGroupId,
    };
  },

  watch: {
    groupId() {
      this.$emit('onchange', this.groupId);
    },
    // As data is not reactive to props we need to change it manually by watching it
    selectedGroupId() {
      this.groupId = this.selectedGroupId;
      // As we have a new selectedGroupId, something might have changed from the fetched groups, so refetch.
      this.$apollo.queries.groups.refetch();
    },
  },

  apollo: {
    groups: {
      fetchPolicy: 'network-only',

      query() {
        if (this.$user.role === 'COACH') {
          return gql`
            query StudentForm_GetCoachingGroups($id: ID!) {
              getUserById(id: $id) {
                id
                coachingGroups {
                  id
                  name
                }
              }
            }
          `;
        }

        return gql`
          query StudentForm_GetOrganisationGroups($id: ID!, $first: Int) {
            getOrganisationById(id: $id) {
              id
              groups(first: $first) {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
            }
          }
        `;
      },

      variables() {
        if (this.$user.role === 'COACH') {
          return {
            id: this.$user.id,
          };
        }

        return {
          id: this.$route.params.resourceId,
        };
      },

      update(data) {
        if (this.$user.role === 'COACH') {
          return data.getUserById.coachingGroups;
        }
        return (
          data.getOrganisationById?.groups?.edges?.map((edge) => edge.node) ??
          []
        );
      },
    },
  },
};
</script>

<style lang="scss">
.group-inputs {
  display: grid;
  gap: 1rem;
  grid-auto-columns: max-content;
  grid-auto-flow: column;
  align-items: center;
}
</style>
