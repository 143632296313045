import gql from 'graphql-tag';

export const useIsSuggestionEnabledForSchool = {
  data() {
    return {
      isSuggestionsEnabledForSchool: false,
    };
  },
  apollo: {
    getOrganisationById: {
      query: gql`
        query getOrganisationById($id: ID!) {
          getOrganisationById(id: $id) {
            id

            ... on School {
              cardSuggestionsEnabled
            }
          }
        }
      `,
      fetchPolicy: 'network-only',
      variables() {
        return {
          // organisationId
          id: this.$route.params.resourceId,
        };
      },

      update(data) {
        this.isSuggestionsEnabledForSchool =
          data.getOrganisationById.cardSuggestionsEnabled;
      },
    },
  },
};
