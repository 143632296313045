<template>
  <div
    class="sb_list-item"
  >
    <div
      class="sb_list-item_label"
    >
      {{ label }}
    </div>
    <div
      class="sb_list-item_value"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SbListItem',
  props: {
    /**
    * The label
    */
    label: {
      type: String,
      default: ''
    }
  }
};
</script>

<style lang="scss" scoped>
  .sb_list-item {
    position: relative;
    display: flex;
    padding: 10px 10px 10px 25px;

    &_label {
      flex: 0 0 200px;
      color: $brand-dark-gray;
    }

    .sb_info {
      top: 12px !important;
      left: -22px !important;
    }
  }
</style>
