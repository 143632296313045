<template>
  <div>
    <template>
      <sb-page-header>
        <template v-slot:left>
          <h1>Groep {{ courseGroupId ? 'aanpassen' : 'toevoegen' }}</h1>
        </template>
        <template v-slot:right> </template>
      </sb-page-header>

      <sb-divider title="Gegevens" />
    </template>
    <sb-spacer height="20" />

    <i-form
      ref="group"
      :model="group"
      :rules="validateFormAddStudentGroup"
      :label-width="160"
    >
      <form-item
        id="GroupNameInput"
        label="Naam"
        prop="name"
        style="margin-bottom: 0"
      >
        <i-input v-model="group.name" placeholder="Naam" />
      </form-item>
    </i-form>

    <sb-divider title="Begeleiders toewijzen aan groep" />

    <template v-if="$user.role === 'MANAGER'">
      <i-button @click="createCoach = true">
        Nieuwe begeleider toevoegen
      </i-button>
      <sb-spacer height="20" />
    </template>

    <transfer
      v-if="coaches"
      id="ConnectCoachesBox"
      :data="allCoaches"
      :target-keys="selectedCoachIds"
      :titles="['Beschikbare begeleiders', 'Begeleiders in groep']"
      :render-format="renderTransferItem"
      :operations="['Groep uit', 'Groep in']"
      :filter-placeholder="'Zoek op naam'"
      filterable
      :filter-method="filterMethod"
      @on-change="onCoachTransferChange"
    />

    <sb-spacer height="30" />

    <sb-divider title="Leerlingen toewijzen aan groep" />

    <template v-if="$user.role === 'MANAGER'">
      <i-button @click="createStudent = true"
        >Nieuwe leerling toevoegen</i-button
      >
      <sb-spacer height="20" />
    </template>

    <transfer
      v-if="students"
      id="ConnectStudentsBox"
      :data="allStudents"
      :target-keys="selectedStudentIds"
      :titles="['Leerlingen zonder klas', 'Leerlingen in deze groep']"
      :render-format="renderTransferItem"
      :operations="['Groep uit', 'Groep in']"
      :filter-placeholder="'Zoek op Leerling naam'"
      filterable
      :filter-method="filterMethod"
      @on-change="onStudentTransferChange"
    />

    <sb-spacer height="400" />

    <sb-bottom-fixed-buttons>
      <template #right>
        <i-button
          id="SaveGroupButton"
          type="primary"
          size="large"
          @click="handleSubmit('group')"
        >
          {{ editMode ? 'Opslaan' : 'Toevoegen' }}
        </i-button>
      </template>
    </sb-bottom-fixed-buttons>

    <sb-drawer :show="createCoach" @close="createCoach = false">
      <sb-coach-form
        :key="createCoach"
        ref="coachForm"
        :default-data="createCoachData"
        default-mode="edit"
        :with-bottom-bar="false"
        :organisation-id="$route.params.resourceId"
        :force-create-mode="true"
        :navigate-back-on-submit="false"
        :display-mode-on-submit="false"
        @submit="handleCoachFormSubmit"
      />

      <sb-bottom-fixed-buttons
        :style="{
          width: '680px',
          padding: 0,
          left: 'unset',
          right: 0,
        }"
      >
        <template #right>
          <i-button
            type="primary"
            style="margin-right: 2rem"
            @click="submitCoachForm"
          >
            Opslaan en toevoegen aan groep
          </i-button>
        </template>
      </sb-bottom-fixed-buttons>
    </sb-drawer>

    <sb-drawer :show="createStudent" @close="createStudent = false">
      <sb-student-form
        :key="createStudent"
        ref="studentForm"
        :default-data="createStudentData"
        default-mode="edit"
        :with-bottom-bar="false"
        role="STUDENT"
        :organisation-id="$route.params.resourceId"
        :disabled-fields="['followingGroupsIds']"
        :force-create-mode="true"
        :navigate-back-on-submit="false"
        :prevent-display-mode-on-submit="true"
        @submit="handleStudentFormSubmit"
      />
      <sb-bottom-fixed-buttons
        :style="{
          width: '680px',
          padding: 0,
          left: 'unset',
          right: 0,
        }"
      >
        <template #right>
          <i-button
            type="primary"
            style="margin-right: 2rem"
            @click="submitStudentForm"
          >
            Opslaan en toevoegen aan groep
          </i-button>
        </template>
      </sb-bottom-fixed-buttons>
    </sb-drawer>
  </div>
</template>

<script>
import DOMPurify from 'dompurify';
import SbBottomFixedButtons from '@/components/SbBottomFixedButtons';
import SbCoachForm from '@/components/SbCoachForm.vue';
import SbDivider from '@/components/SbDivider';
import SbDrawer from '@/components/SbDrawer';
import SbPageHeader from '@/components/SbPageHeader';
import SbStudentForm from '@/components/SbStudentForm/SbStudentForm.vue';
import AddUsersToGroup from '@/graphql/mutations/AddUsersToGroup.gql';
import CreateGroup from '@/graphql/mutations/CreateGroup.gql';
import RemoveUsersFromGroup from '@/graphql/mutations/RemoveUsersFromGroup.gql';
import UpdateGroup from '@/graphql/mutations/UpdateGroup.gql';
import CourseGroupCoaches from '@/graphql/queries/CourseGroupCoaches.gql';
import CourseGroupStudents from '@/graphql/queries/CourseGroupStudents.gql';
import { roleMixin } from '@/lib/role';
import { mapActions } from 'vuex';

export default {
  components: {
    SbBottomFixedButtons,
    SbDivider,
    SbPageHeader,
    SbDrawer,
    SbStudentForm,
    SbCoachForm,
  },

  mixins: [roleMixin],

  props: {
    resourceId: {
      type: String,
      default: null,
    },
    courseGroupId: {
      type: String,
      default: null,
    },
    data: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      createStudentData: {
        followingGroupsIds: [this.$route.params.courseGroupId].filter(Boolean),
      },
      createStudent: false,
      createCoachData: {
        coachingGroupsIds: [this.$route.params.courseGroupId].filter(Boolean),
      },
      createCoach: false,
      selectedCoachIds: [],
      selectedStudentIds: [],
      group: { name: null },
      validateFormAddStudentGroup: {
        name: [
          {
            required: true,
            message: 'Voer een naam in',
            trigger: 'blur',
          },
        ],
      },
      originalCoachIds: null,
      originalStudentIds: null,
    };
  },

  computed: {
    editMode() {
      return !!this.data;
    },

    allStudents() {
      return [...(this.students ? this.students : [])].map((user) => {
        return {
          key: user.id,
          ...user,
        };
      });
    },

    allCoaches() {
      return [...(this.coaches ? this.coaches : [])].map((user) => {
        return {
          key: user.id,
          ...user,
        };
      });
    },
  },

  watch: {
    data() {
      this.populateData();
    },
    // students() {
    //   this.populateData();
    // },
  },

  mounted() {
    if (this.data) {
      this.populateData();
    }

    const currentTourKey = 'MANAGER_GROUP_ADD';
    this.enqueueTour(currentTourKey);
  },

  apollo: {
    students: {
      query: CourseGroupStudents,
      fetchPolicy: 'network-only',
      variables() {
        return {
          id: this.resourceId,
          first: 1000,
        };
      },
      update(data) {
        return data.getOrganisationById.students.edges.map((edge) => edge.node);
      },
    },
    coaches: {
      query: CourseGroupCoaches,
      fetchPolicy: 'network-only',
      variables() {
        return {
          id: this.resourceId,
          first: 1000,
        };
      },
      update(data) {
        return data.getOrganisationById.coaches.edges.map((edge) => edge.node);
      },
    },
  },

  methods: {
    ...mapActions('onboarding', ['enqueueTour']),
    async handleCoachFormSubmit(event) {
      this.skipPopulateData = true;
      await this.$apollo.queries.coaches.refetch().then(async () => {
        await this.$nextTick();
        this.skipPopulateData = false;
      });
      this.selectedCoachIds.push(event.id);
      this.createCoachData = {
        coachingGroupsIds: [this.$route.params.courseGroupId].filter(Boolean),
      };
      this.createCoach = false;
    },

    submitCoachForm() {
      const form = this.$refs.coachForm.$refs.form.$refs.baseForm;
      form.handleSave();
    },

    async handleStudentFormSubmit(event) {
      this.skipPopulateData = true;
      await this.$apollo.queries.students.refetch().then(async () => {
        await this.$nextTick();
        this.skipPopulateData = false;
      });
      this.selectedStudentIds.push(event.id);
      this.createStudentData = {
        followingGroupsIds: [this.$route.params.courseGroupId].filter(Boolean),
      };
      this.createStudent = false;
    },

    submitStudentForm() {
      const form = this.$refs.studentForm.$refs.form.$refs.baseForm;
      form.handleSave();
    },

    populateData() {
      console.log('skip?', this.skipPopulateData);
      if (this.skipPopulateData) return;

      this.$set(
        this,
        'selectedCoachIds',
        Array.from(
          new Set([
            ...(this.data?.coaches.edges.map((edge) => edge.node.id) ?? []),
            ...(this.selectedCoachIds ?? []),
          ]),
        ),
      );

      this.$set(
        this,
        'selectedStudentIds',
        Array.from(
          new Set([
            ...(this.data?.students.edges.map((edge) => edge.node.id) ?? []),
            ...(this.selectedStudentIds ?? []),
          ]),
        ),
      );

      this.originalCoachIds = JSON.parse(JSON.stringify(this.selectedCoachIds));
      this.originalStudentIds = JSON.parse(
        JSON.stringify(this.selectedStudentIds),
      );

      this.group.name = this.data?.name;
    },
    async handleAdd() {
      console.log('add');
      const { data } = await this.$apollo.mutate({
        mutation: CreateGroup,
        variables: {
          input: {
            schoolId: this.resourceId,
            studentIds: this.selectedStudentIds,
            coachIds: this.selectedCoachIds,
            ...this.group,
          },
        },
      });

      this.$Modal.success({
        title: 'Succes',
        content: this.editMode
          ? 'Het wijzigen is gelukt'
          : 'Het aanmaken is gelukt',
        onOk: () => {
          this.$router.navigateBack();
        },
      });

      this.$refs.group.resetFields();
      // this.$Message.success(this.editMode ? 'Het wijzigen is gelukt' : 'Het aanmaken is gelukt');
    },
    async handleEdit() {
      console.log('edit', this.originalCoachIds);
      const coachesToRemove = this.originalCoachIds.filter(
        (coachId) => !this.selectedCoachIds.includes(coachId),
      );
      const studentsToRemove = this.originalStudentIds.filter(
        (studentId) => !this.selectedStudentIds.includes(studentId),
      );

      const coachesToAdd = this.selectedCoachIds.filter(
        (coachId) => !this.originalCoachIds.includes(coachId),
      );
      const studentsToAdd = this.selectedStudentIds.filter(
        (studentId) => !this.originalStudentIds.includes(studentId),
      );

      console.log({
        coachesToRemove,
        studentsToRemove,
        coachesToAdd,
        studentsToAdd,
      });

      if (coachesToRemove.length || studentsToRemove.length) {
        const { data: removedUsers } = await this.$apollo.mutate({
          mutation: RemoveUsersFromGroup,
          variables: {
            input: {
              id: this.courseGroupId,
              data: {
                coachIds: coachesToRemove,
                studentIds: studentsToRemove,
              },
            },
          },
        });

        console.log({
          removedUsers,
        });
      }

      if (coachesToAdd.length || studentsToAdd.length) {
        const { data: addedUsers } = await this.$apollo.mutate({
          mutation: AddUsersToGroup,
          variables: {
            input: {
              id: this.courseGroupId,
              data: {
                coachIds: coachesToAdd,
                studentIds: studentsToAdd,
              },
            },
          },
        });
        console.log({
          addedUsers,
        });
      }

      const { data: updateData } = await this.$apollo.mutate({
        mutation: UpdateGroup,
        variables: {
          input: {
            id: this.courseGroupId,
            data: {
              name: this.group.name,
            },
          },
        },
      });
      console.log({
        updateData,
      });

      this.$Message.success('De groep is succesvol gewijzigd');
    },
    handleSubmit(name) {
      this.$refs[name].validate(async (valid) => {
        if (valid) {
          console.log('handle submit');
          const res = this.editMode
            ? await this.handleEdit()
            : await this.handleAdd();
          console.log(res);
        } else {
          this.$Message.error(
            'Er zijn een aantal fouten, controleer je invoer.',
          );
        }
      });
    },
    onCoachTransferChange(newTargetKeys) {
      this.selectedCoachIds = newTargetKeys;
    },
    onStudentTransferChange(newTargetKeys) {
      this.selectedStudentIds = newTargetKeys;
    },
    renderTransferItem(item) {
      return DOMPurify.sanitize(item.fullName, { ALLOWED_TAGS: [] });
    },
    filterMethod(item, query) {
      return item.fullName.toLowerCase().includes(query.toLowerCase());
    },
  },
};
</script>

<style lang="scss" scoped>
.course-group-add-edit-drawer-bottom-bar {
  height: rem-calc(88);
  width: 100%;
  position: absolute;
  bottom: 0;
  background: $brand-white;
  box-shadow: 0px -5px 5px rgba($brand-black, 0.1);
}
</style>
