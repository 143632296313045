export const validationRules = {
  coachingGroupsIds: [
    {
      required: true,
      message: 'Selecteer een Groep',
      trigger: 'change',
      type: 'array',
      min: 1,
      validator(rule, value, callback) {
        if (value.length >= rule.min && !!value[0]) {
          return callback();
        }
        callback(new Error(rule.message));
      },
    },
  ],
  followingGroupsIds: [
    {
      required: true,
      message: 'Selecteer een Groep',
      trigger: 'change',
      type: 'array',
      min: 1,
      validator(rule, value, callback) {
        if (value.length >= rule.min && !!value[0]) {
          return callback();
        }
        callback(new Error(rule.message));
      },
    },
  ],
  classYear: [
    {
      // required: true,
      message: 'Selecteer een groep',
      trigger: 'change',
    },
  ],
  zelfredzaamheid: [
    {
      required: true,
      message: 'Vul zelfredzaamheid in',
      trigger: 'change',
    },
  ],
};
