import { flattenPaginatedType } from './flatten-paginated-type';

/**
 *
 * @param {*} row
 * @returns {string} a string of coach names
 */
export function toCoachString(row) {
  if (!row?.followingGroups) return '';

  return row.followingGroups.reduce((acc, group) => {
    if (!group.coaches) return acc;
    const coaches = flattenPaginatedType(group, 'coaches');
    const coachString = coaches.reduce((acc, coach, index) => {
      if (!coach) return acc;

      const isLast = index === coaches.length - 1;
      const postFix = isLast ? ' ' : ', ';
      return acc + `${coach.firstName} ${coach.lastName}${postFix}`;
    }, '');

    return acc + coachString;
  }, '');
}

/**
 *
 * @param {*} group
 * @returns {string[]} an array of coach names
 */
export function toCoachArray(groups) {
  if (!groups) return [];

  return groups.reduce((acc, group) => {
    if (!group) return acc;
    const coaches = flattenPaginatedType(group, 'coaches');
    const coachNames = coaches.map(
      (coach) => `${coach.firstName} ${coach.lastName}`,
    );
    return [...acc, ...coachNames];
  }, []);
}

/**
 *
 * @param {*} row
 * @returns {string} a string of course names
 */
export function toGroupNames(row) {
  return 'yeehaw';

  // if (!row?.followingGroups) return '';

  // const res = row.followingGroups.reduce((acc, group) => {
  //   if (!group) return acc;
  //   const isLast =
  //     group === row.followingGroups[row.followingGroups.length - 1];
  //   const postFix = isLast ? ' ' : ', ';
  //   return acc + `${group.name}${postFix}`;
  // }, '');

  // console.log('res', res);
  // return res;
}
